.wrapper {
  width: 500px;
  height: auto;
  margin-bottom: 40px;
  margin-top: -22px;

  &_likeAndDislike_msg {
    width: fit-content;
    background-color: transparent;
    // border: 1px solid #c6c8cc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 8px 16px 8px 16px;
  }

  &_spanMsg {
    font-family: foundersGroteskTextRegular;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #595a5a;
    padding-right: 16px;
  }

  &_likeAndDislike {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  &_box {
    gap: 12px;
    height: 16px;
    margin-bottom: 4px;
  }

  .point {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }

  &_greetingTextBox {
    // margin-top: 16px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &_inputBox {
      width: 500px !important;
      padding: 12px 30px 12px 16px;
      border-radius: 5px;
      border: 1px solid #c6c8cc;
      color: #595a5a;
      background-color: transparent;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.25px;
      font-family: foundersGroteskTextRegular;
    }

    .cross_btn {
      position: absolute;
      right: 0;
      display: flex;
    }

    button.cross_btn {
      border: none !important;
      background-color: transparent !important;
    }
  }
}

.modal {
  justify-content: center !important;
  align-items: center !important;
}

.modal-content {
  padding: 0px 0px 30px 0px;
  background-color: #ffffff !important;
  border-radius: 10px !important;
}

.modal-content_inner {
  padding: 0px 30px 0px 30px;
}

.modal-header {
  border-bottom: none !important;
  height: 44px !important;
  padding: 15px 0px 15px 15px !important;
  gap: 10px !important;
  justify-content: end;

  .closebtn {
    background: none !important;
    border: none !important;
    margin-right: -5% !important;
    margin-bottom: 5px;
  }

  .close_icon {
    width: 14px !important;
    height: 14px !important;
  }
}

.modal-body {
  gap: 20px;

  &__title {
    height: 22px;
  }

  &_headingText {
    font-family: leJeuneDeck;
    font-weight: 400;
    font-size: 18px;
    line-height: 21.6px;
    color: #041e3a;
  }

  &_suggestedFeedback {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .feedbackMsg {
      color: #041e3a;
      background-color: white;
      border: 1px solid #9d9fa3;
      font-family: foundersGroteskTextRegular;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      border-radius: 5px;
      padding: 10px 12px 10px 12px;
    }
    .feedback_optional_box {
      width: 440px;
      padding: 14px 14px 20px 14px;
      font-family: foundersGroteskTextRegular;
      color: #041e3a;
      border: 1px solid #c6c8cc;
      border-radius: 5px;
      margin-top: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0.25px;
    }
    .feedback_optional_box:focus-visible {
      outline: none !important;
    }
  }
}

input.feedbackMsg.selected {
  background-color: #f2f3f5 !important;
  border: 1px solid #041e3a !important;
}

.modal {
  --bs-modal-padding: 0rem !important;
}

.feedback_optional_box::placeholder {
  color: #6d6f73;
  opacity: 1;
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.25px;
}

.submitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0 0 0;
}

.btnSize {
  width: 100% ;
  font-family: foundersGroteskTextMedium !important;
  line-height: 14px !important;
  font-size: 14px !important;
  padding: 19px 26px 19px 26px !important;
  border-radius: 5px !important;
}
#btnStyle {
  outline: 0 !important;
  box-shadow: none !important;
}

.normalbtnClr {
  color: white;
  background-color: #c6cbcc !important;
  border: none !important;
}

.activeBtnColor {
  background-color: #041e3a !important;
  color: white !important;

  &:focus {
    outline: 0 !important;
    background-color: #041e3a;
    color: white;
  }

  &:hover {
    background-color: #041e3a !important;
    color: white;
  }
}

#btnStyle:focus {
  outline: 0 !important;
}

#btnStyle:focus {
  outline: 0 !important;
  background-color: #041e3a;
  color: white;
}

button.btnSize:hover {
  background-color: #c6cbcc;
  color: white;
}

#myModal {
  backdrop-filter: blur(0.5px);
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1 !important;
}
.feedback {
  background: none;
  border: none;
  align-self: center;
  transform: translateY(5%);
  padding-top: 3px;
}
.fb_text{
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #595a5a;
  margin-bottom: 7px !important;
}
/***************   Media Queries   ********************/

//desktop
@media screen and (min-width: 960px) {
  .modal-content {
    width: 500px !important;
  }
  .modal-body {
    width: 440px !important;
  }
}
.feedbackMsg {
  &:hover {
    background-color: #f2f3f5 !important;
    border: 1px solid #041e3a !important;
  }
}

// mobile
@media screen and (max-width: 959px) {
  .wrapper {
    margin-bottom: 30px;
    margin-top: -20px;
    &_likeAndDislike_msg {
      padding: 6px 16px 6px 16px;
      margin-top: -8px;
    }
    .point {
      width: 13px;
      height: 13px;
    }
  }
}

// xs breakpoint
@media screen and (max-width: 576px) {
  .modal {
    align-items: end !important;
    --bs-modal-width: 100% !important;
  }
  .modal-dialog {
    width: 100%;
    margin: 0;
  }
  .closebtn {
    margin-right: -4% !important;
    padding: 0 !important;
  }
  .feedback_optional_box {
    width: 100% !important;
  }
}
@media screen and (max-width: 540px) {
  .wrapper {
    width: 100%;
    &_greetingTextBox {
      width: 100%;
    }
  }
}

// sm and md breakpoint
@media screen and (min-width: 577px) and (max-width: 959) {
  .modal-content_inner {
    width: 500px !important;
  }
}
