.mb {
  margin-bottom: 60px;
}
.response_bg {
  background-color: #ffffff;
  padding: 15px 20px 15px 20px;
  border-radius: 10px;
}
.common_btn {
  border: 0;
  background: #041e3a;
  padding: 8px 25px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 25px;
}

.main-section {
  .para-main {
    font-family: foundersGroteskTextRegular;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    font-style: normal;
    color: $bs-head-color;
  }
}

.divider_margin {
  margin-bottom: 2px;
}

.chat_options {
  .chat_msg {
    padding: 15px 20px;
    gap: 10px;
    background: #e1e1e4;
    width: fit-content;
    max-width: 800px;
    font-size: 16px;
    line-height: 32px;
    color: var(--polo-primary, #041e3a);
    font-weight: 400;
    font-family: foundersGroteskTextRegular;
    margin-bottom: 40px;
    border-radius: 10px;
    span.badge-text {
      margin-top: 1%;
      // display: inline-block;
      // text-align: justify;
      // word-spacing: 0.05em;
      // max-width: 100%;
      // width: auto;
      // white-space: normal;
      // word-break: normal;
      // hyphens: auto;
      word-wrap: break-word;
      box-sizing: border-box;
      // word-break: break-all;
    }
    .user_bubble {
      color: #6d6f73;
      font-size: 10px;
      line-height: normal;
      padding: 12px;
      border-radius: 3px;
      background: var(--collection-warm-grey-4, #e1e1dc);
      font-family: foundersGroteskMonoMedium;
      margin-right: 12px;
      margin-left: 2px;
    }
  }
  .chat_bubble_spacing {
    height: 60px !important;
  }
  .response_div_spacing {
    height: 62px !important;
  }
}

.product-detail {
  font-size: 16px !important;
  line-height: 30px !important;
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  color: #041e3a;
  font-style: normal;
}

.send-arrow-position {
  position: absolute;
  right: 15px;
}

.send-arrow-position.enabled {
  cursor: pointer;
  border: none;
  background: none;
}

.send-arrow-position.disabled {
  border: none;
  background: none;
}

.active {
  opacity: 1;
}

.horizontal_dash {
  border-top: 1px dashed;
  border-image-source: url(../../../public/static/assets/horizontal_dash.png);
  border-image-repeat: repeat;
  border-image-slice: 5;
}

.utility_icons {
  height: 13px;
  width: 13px;
  margin-right: 10px;
}

.utility-btn {
  border: 1px solid rgba(4, 30, 58, 0.5);
  text-transform: uppercase;
  background: none;
  padding: 12px 22px 11px 20px;
  margin-right: 11px;
  border-radius: 100px;
  gap: 12px;
  font-size: 10px;
  line-height: 12.7px;
  letter-spacing: 1px;
  font-family: foundersGroteskTextMedium;
  color: var(--polo-primary, #041e3a);
  transition: background-color 0.3s ease-out;
}
.shop_icon {
  margin-bottom: 0px;
  margin-top: -2px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
}
.styles_icon {
  margin-right: 7px;
  margin-top: -1px;
  margin-bottom: 1px;
}

.player-container {
  position: relative;
  overflow: hidden;
}

// react player play/pause button
.play-pause-overlay {
  bottom: 11%;
  margin-left: 13px;
  position: absolute;
  display: flex;
  color: #041e3a;
  cursor: pointer;
}
.sp-play-pause-overlay {
  padding-left: 12px;
  padding-bottom: 12px;
  position: absolute;
  display: flex;
  color: #041e3a;
  cursor: pointer;
}

.play-pause {
  width: 27px;
}

.divider {
  margin-top: 40px;
  margin-bottom: 40px;
}
p {
  margin-bottom: 0 !important;
}
.text_res {
  margin-bottom: 16px;
  // p {
  //   margin-bottom: 24px !important;
  // }
}
.down-arrow {
  position: fixed;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrDisable {
  cursor: default;
}

.arrEnable {
  cursor: pointer;
}
/************** Media Queries ***************/

.play-pause-overlay {
  @media screen and (min-width: 1400px) {
    /* xxl breakpoint*/
    bottom: 3.5%;
    margin-left: 1.5%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    /* xl breakpoint*/
    bottom: 18%;
    margin-left: 2.5%;
  }
  @media screen and (min-width: 992px) and (max-width: 1023px) {
    /* lg breakpoint*/
    bottom: 8%;
    margin-left: 2.5%;
  }
  @media screen and (min-width: 577px) and (max-width: 767px) {
    /* sm breakpoint*/
    margin-left: 2.5%;
    bottom: 6.5%;
  }
  @media screen and (max-width: 576px) {
    /* xs breakpoint*/
    margin-left: 2%;
    bottom: 6%;
  }
}

//desktop
@media screen and (min-width: 960px) {
  .chat_options {
    // padding-bottom: 16px;
    .chat_msg {
      margin-bottom: 60px;
    }
  }
  .utility-btn-hover {
    &:hover {
      border: 1px solid #ffffff;
      background: #fff;
    }
  }
}

@media screen and (min-width: 992px) {
  .sp-play-pause-overlay {
    bottom: 0;
    left: 0;
  }
}

// sm breakpoint
@media screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
// md breakpoint
@media screen and (min-width: 768px) and (max-width: 991px) {
  .utility-btn {
    transform: scale(1.1);
    margin-right: 13px;
  }
  .play-pause-overlay {
    margin-left: 2%;
    bottom: 5.5%;
  }
}

// mobile
@media screen and (max-width: 959px) {
  .response_bg {
    padding: 16px 20px 0px 20px;
  }
  .mb {
    margin-bottom: 40px;
  }
  .main-section {
    .para-main {
      font-size: 13px;
      font-style: normal;
      line-height: 22px;
      color: var(--polo-primary, #041e3a);
    }
  }
  .chat_options {
    .chat_msg {
      padding: 16px 20px;
      font-size: 13px;
      // max-width: 450px;
      font-family: foundersGroteskTextRegular;
      font-weight: 400;
      line-height: 22px;
      margin-right: 1px !important;
      .user_bubble {
        margin-left: 0px;
        padding: 7px;
      }
    }
  }
  .chat_bubble_spacing {
    height: 52px !important;
  }
  .response_div_spacing {
    height: 50px !important;
  }
  .product-detail {
    font-size: 13px;
    line-height: 22px;
  }
  .utility-btn-hover {
    &:active {
      background: #fff;
      border: 1px solid #ffffff;
    }
  }
  .divider {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .text_res {
    margin-bottom: 14px;
  }
  p {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  .utility_icons {
    height: 11px;
    width: 11px;
    margin-right: 0px;
  }
  .utility-btn {
    padding: 10px 12px 10px 12px;
    background: #ffffff;
    border: none !important;
  }
  .shop_icon {
    margin-right: 0px;
    height: 13px;
    width: 13px;
  }
  .styles_icon {
    margin-right: 0px;
  }
  .sp-play-pause-overlay {
    bottom: 2%;
    left: 6%;
  }
}
// background image for header
.imgHeader {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}
