.background-image {
  height: 80vh;
  width: 100%;
  object-fit: cover;
  margin-bottom: -20%; /* Adjust this value to minimize the gap */
  box-shadow: 0px 5px 10px 0px #0000000d;
  border: none !important;
}

.card {
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 20px !important;
  padding: 40px 30px 24px 30px;
  margin-bottom: 40px;
  margin-top: -8rem;
  border: none !important;
  box-shadow: 0px 5px 10px 0px #0000000D;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-title {
  width: 100%;
  font-family: leJeuneDeck;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  box-sizing: border-box;
  gap: 8px;
  color: #041e3a !important;
  margin-bottom: 10px !important;
}
.card-text {
  font-family: foundersGroteskTextRegular;
  font-size: 13px;
  line-height: 22px;
  gap: 10px;
  color: #041e3a;
  margin-bottom: 30px !important;
  align-self: start;
}
.card-text:last-child {
  margin-top: 30px !important;
}

.products {
  border: none !important;
  background: #f0efed;
  aspect-ratio: 4 / 5;
}

.bs_gutter {
  --bs-gutter-x: 2px !important;
  --bs-gutter-y: 2px !important;
}
.placeholder-img {
  background: #f0efed;
  border: none;
  height: 100%;
  width: 100%;
}

/****************  Media Queries  ****************/
@media screen and (max-width: 576px) {
  .card {
    margin-top: -14rem;
  }
}

/**************** Animation *************************/
.animate-header {
  animation: animate-header 1s forwards;
  opacity: 0;
  @keyframes animate-header {
    0% {
      opacity: 0;
    }
    50%{
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}
.animate-card {
  animation: animate-card 1s forwards;
  animation-delay: 1s;
  display: none;
  opacity: 0;
  @keyframes animate-card {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.animate-card-title {
  animation: animate-card-title 1s forwards;
  animation-delay: 2s;
  opacity: 0;
  @keyframes animate-card-title {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.animate-card-text {
  animation: animate-card-text 1s forwards;
  animation-delay: 2.5s;
  opacity: 0;
  @keyframes animate-card-text {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.animate-conclusion {
  animation: animate-conclusion 1s forwards;
  animation-delay: 3s;
  opacity: 0;
  @keyframes animate-conclusion {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
