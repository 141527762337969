.carousel .slider-wrapper {
  overflow: visible !important;
  width: 86% !important;
}
 
.positionCarouselOnboard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
 
 
.progress,
.progress-stacked {
  --bs-progress-height: 0.15rem !important;
  background-color: grey !important;
  --bs-progress-bar-bg: #fff !important;
}
.onboard_description{
  left: 6.5% !important;  
}
 
.head_line {
  font-family: leJeuneDeck;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: white;
}
.sub_text {
  font-family: foundersGroteskTextRegular;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.78px;
  letter-spacing: 0.559999942779541px;
  text-align: left;
  color: white;
  margin-top: 8px;
}
.skip-text {
  font-family: foundersGroteskMonoMedium;
  font-size: 11px;
  line-height: 10px;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  border: 0;
  padding-bottom: 3px;
  border-bottom: 1px solid #ffffff;
  text-transform: uppercase;
  background: none;
}
 
.opacityCarouselItems {
  animation: movefromBottomSearch 0.5s forwards ease-in;
}
 
@keyframes fadeOutToTop {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
 
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
 
.opacityCarousel {
  animation: fadeOutToHigh 2s linear;
}
 
@keyframes fadeOutToHigh {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
 
.opacityCarouselItemsReverse {
  animation: fadeOutToBottom 0.5s forwards ease-in;
}
 
@keyframes fadeOutToBottom {
  0% {
    opacity: 1;
    transform: translate(0);
  }
  100% {
    opacity: 0;
    transform: translateY(30px);
  }
}
 
 
@keyframes onboard {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateY(-2.9rem);
  }
}
 
.opacityCarouselItems {
  animation: movefromBottomSearch 0.5s forwards ease-in;
}
 
@keyframes movefromBottomSearch {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
 
.fade-in-logo {
  opacity: 0;
  animation: fadeInLogo 1.5s forwards;
}
 
@keyframes fadeInLogo {
  to {
    opacity: 1;
  }
}
 
.fade-out-logo {
  opacity: 1;
  animation: fadeOutLogo 1.5s forwards;
}
 
@keyframes fadeOutLogo {
  to {
    opacity: 0;
  }
}
 
@media (max-width: 768px) {
  .react-multi-carousel-list {
    display: block;
  }
  .blur {
    filter: blur(5px);
  }
}
 
.carousel-container-onboard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
 
  .carousel-container {
    padding: 0;
    margin: 0;
    width: 100%;
  }
 
 
  .nextBtn {
    width: 315px;
    height: 52px;
    border: none;
    text-transform: uppercase;
    font-family: foundersGroteskMonoMedium;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 10px;
    text-align: center;
    color: #041e3a;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn_margin{
    margin: 0px 30px;
  }
}
.footer{
  width: 100%;
}
 
//********************************//********************************
      //************   Media Query     *************//
//********************************//********************************
 
@media screen and (min-width: 577px) {
  .react-multi-carousel-list {
    display: none;
  }
}
 
 
@media screen and (min-width: 420px){
  .onboard_description{
    left: 9.5% !important;
   }
 }
 
@media screen and (max-width: 576px) {
  .animateOnboardCarousel {
    animation: onboard 1.5s forwards ease-in;
  }
  .progress{
    width: 294px;
  }
}
 
@media ((min-width: 376px) and (max-width: 425)) {
  .carousel-container-onboard .carousel .slide {
    min-width: 74% !important;
  }
}
 
// @media (min-width: 1024px) {
//   .carousel-container {
//     display: none; /* Hide carousel on desktop */
//   }
// }
