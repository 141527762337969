// layout styles

.mainlayout {
    // background-image: url(../../../public/static/assets/chatBackground.jpg);
    background-color: #F2F3F5;
    background-size: cover;
    position: relative;
    @media screen and (max-width:959px){
        background-position: 70% top;
    }
 }
    .mainroute {
        width: 100%;
        height: 100vh;
        @media screen and (max-width:430px){             // for iphone fix  
         height: -webkit-fill-available;
         height: -moz-available;
         height: fill-available;
        }
    }
    .mainroute_height{                          
        @media screen and (max-width:430px){
            height: 100vh;
        }   
    }

// @keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
// }
      
// .input--focused {
//     animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
// }
