.cat-loader{
  width: 8px;
  height: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: loader 1s infinite linear alternate;
}
 
@keyframes loader {
  0% {
    box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
    background:#6D6F73;
  }
  33% {
    box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
    background: #0002;
  }
  100% {
    box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
    background: #6D6F73;
  }
}
 
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
 
 
.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  white-space: nowrap;
  height: auto;
  overflow-x: auto;
  padding: 5px;
  margin: 0px 0px 15px 0px;
  scroll-snap-type: x mandatory;
}
 
 
.mcat-text{
  font-size: 16px;
  line-height: 30px;
  font-family: foundersGroteskTextRegular;
  font-style: normal;
  font-weight: 400;
  color: #041E3A;
}
 
.cards::-webkit-scrollbar {
  display: none !important;
}
 
.box {
  position: relative;
  max-width: 320px;
  width: 100% !important;
  height: 430px;
  flex: 0 0 320px;
  background: linear-gradient(191.2deg, #EDE4D9 14.02%, #D0BDA6 104.88%);
  border-radius: 10px;
  border: none;
  transition: transform 0.3s;
  scroll-snap-align:start;
}
 
.box:hover {
  transform: scale(1.03);
  transform-origin: center;
  cursor: pointer;
}
 
.noHover{
  pointer-events: none;
}
 
.effectHover{
  pointer-events: all;
}
 
.textLook {
  position: absolute;
  display: block;
  font-weight: 500;
  font-size: 9px;
  line-height: 9.71px;
  letter-spacing: 1.14px;
  color: #041e3a;
  top: 30px;
  left: 24px;
  font-family: foundersGroteskTextMedium;
  overflow: hidden;
}
 
.content-title_container {
  position: absolute;
  height: 60px;
  top: 44px;
  left: 24px;
}
 
.content-title {
  font-family: leJeuneDeck;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 28px;
  line-height: 34px;
  color: #041e3a;
  max-width: 13ch;
  word-wrap: break-word;
}
 
 
.content-title::after {
  content: attr(data-first-word);
  white-space: pre-line;
  display: inline-block;
}
 
.bottomTextContainer {
  position: absolute;
  top: 393px;
  left: 22.5px;
  border: none;
  background: none;
  width: 60% !important;
}
 
.viewText {
  font-family: foundersGroteskTextMedium;
  position: absolute;
  display: block;
  font-size: 11.25px;
  line-height: 15px;
  color: #041e3a;  
  opacity: 0;
  text-wrap: nowrap;
  top: 0;
}
 
.box:hover .viewText {
  opacity: 1;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
 
.coat1 {
  position: absolute;
  height: 288px !important;
  top: 95px;
  left: 57px;
  z-index: 2;
}
 
.shirt1 {
  height: 216px !important;
  position: absolute;
  top: 132px;
  z-index: 1;
  left: 5px;
}
 
.pant1 {
  position: absolute;
  top: 132px;
  left: 166px;
  z-index: 1;
  height: 216px !important;
}
 
.watch1 {
  position: absolute;
  height: 200px !important;
  top: 208px;
  left: 165px;
  z-index: 3;
}
 
.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}
 
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
.multicat_template{
  .multi-res{
    margin-bottom: 16px !important;
  }
  p {
    margin-bottom: 24px !important;
  }
}
 
//********************************//********************************
      //************   Media Query     *************//
//********************************//********************************
 
@media screen and (max-width: 959px) {
  .cat-loader{
    top: 48px;
  }
  .mcat-text{
    font-size: 13px;
    line-height: 22px;
  }  
  .viewText {
    font-size: 11.25px;
    line-height: 15px;
    opacity: 1;
  }
  .multicat_template{
    .multi-res{
      margin-bottom: 24px !important;
    }
    p{
      margin-bottom: 16px !important;
    }
  }
}

@media screen and ((min-width: 320px) and (max-width: 374px)) {
  .cards {
    display: flex;
    margin-left: -7px;
    margin-right: -20px;
    scroll-padding-left: 8px;
    scroll-padding-right: 20px;
  }
}
 
@media screen and ((min-width:375px) and (max-width: 576px)) {
  .cards {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  gap: 16.88px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  }
}
 
@media screen and ((min-width: 577px) and (max-width:992px)) {
  .cards {
  display: flex;
  margin-left: -4px;
  margin-right: 0px;
  gap: 16.88px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  }
}
 
@media screen and (min-width: 992px) {
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Ensures three columns */
    gap: 18px;
    margin-left: -4px;
    margin-right: 0px;
    overflow-x: hidden;
    margin-top: 6px;
    margin-bottom: 30px;
  }
}
 
@media screen and (min-width: 1200px)  {
  .cards {
    display: flex;
    grid-template-columns: repeat(3, 1fr); /* Ensures three columns */
    gap: 18px;
    margin-top: 6px;
    margin-bottom: 30px;
  }
  .watch1 {
    left: 170px;
  }
}
 
@media screen and ((min-width: 992px) and (max-width:1200px)) {
  .watch1 {
    left: 155px;
  }
}
 
 
@media screen and (max-width: 576px) {
  .mcat-box-ms{
    margin-left: 20px;
  }
  .mcat-box-me{
    margin-right: 20px !important;
  }
  .box {
    width: 100%;
    height: 403.13px;
    border-radius: 9.38px;
    transform: none;
    transition: none;
    flex: 0 0 300px;
  }
  .box:hover {
    transform: none;
  }
  .textLook {
    font-size: 8.44px;
    line-height: 9.11px;
    letter-spacing: 1.07px;
  }
  .content-title_container {
    height: 57px;
    top: 45px;
    left: 22.5px;
    text-wrap: auto;
    text-align: left;
  }
  .content-title {
    font-size: 26.25px;
    line-height: 31.88px;
    letter-spacing: 1.07px;
  }
  .bottomTextContainer {
    height: 15px;
    top: 373.13px;
    left: 22.5px;
  }
 
}
 
@media screen and (max-width: 375px) {
.textLook {
  font-size: 8.44px;
  line-height: 9.11px;
  letter-spacing: 1.07px;
}
}
 
 
@media screen and (min-width: 577px) and (max-width: 767px){
  .mcat-box-me{
    margin-right: -5px;
  }
}
@media screen and (min-width:768px)and (max-width:959px){
  .mcat-box-me{
  margin-right: -1px;
  }
}