.button{
    width: 44px;
    height: 44px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-icon{
    width: 24px;
    height: 24px;
}
 
 
 
.btn-custom-blue{
    background-color: #041E3A;
    font-family: foundersGroteskTextMedium;
    border: none;
}
.btn-custom-white{
    background-color:#FFFFFF ;
    font-family: foundersGroteskTextMedium;
    border: #FFFFFF;
   
}
.button-label{
    text-align: center;
    margin-top: 8px;
    font-size: 10px;
    line-height: 12.7px;
    letter-spacing: 1px;
    font-family: foundersGroteskTextMedium;
    color: #041E3A;
}
 
 
@media screen  and  (max-width:576px){
    .modal{
        align-items: end !important;
        --bs-modal-width: 100% !important;
    }
    .modal-dialog-main {
        width: 100%;
        margin: 0;
        pointer-events: none;
    }
    .main-content{
        width: 100%;
        height: auto;
    }
    .main.footer{
        justify-content: center;
        width: 100%;
        padding: 0px, 0px, 0px, 20px
    }
    .main.footer{
        width: 100%;
        height: fit-content;
    }
    .modal-close-icon{
        align-self: end;
        text-align: end;
        display: flex;  
    }
    .delete-btn{
        height: fit-content;
        width: 48.4%;
    }
    .cancel-btn{
        width: 48.4%;
 
    }
}
 
@media screen  and  (max-width:576px){
    .modal{
        align-items: end !important;
        --bs-modal-width: 100% !important;
    }
   
}
 
///desktopcss/////
@media screen  and (min-width:960px){
    .modal-dialog-main{
        width:471px !important;  
        height: 225px;
      }
    }
.main-content{
    align-items: center;
    padding: 0px, 0px, 30px, 20px !important;
}
.main-header{
    .modal-close-icon{
     
        width: 16.08px;
        height: 16px;
        margin-right:-12.5rem;
        gap: 10px;
        top:26px;
        margin-top: 12px;
    }
   
}
.modal-close-icon{
    align-self: end;
    text-align: end;
    display: flex;  
    margin:24px 40px 10px 10px;
    background: none !important;
    border: none !important;
    padding: 0;
    @media screen and (max-width:767px){
        margin:24px 40px 10px 10px;   
    }
}
.main.footer{
    display: flex;
 
}
 
 
.main-body-title{
    padding: 21px 30px 30px 30px;
    gap:30px;
    height: fit-content;
 
 
    .modal_body_title1{
       
        font-family: leJeuneDeck;
       font-size: 18px;
       line-height: 24px;
       color: var(--polo-primary, #041e3a);
    }
    .modal_body_title2{
 
        font-family: foundersGroteskTextRegular;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: var(--polo-primary, #041e3a);
 
    }
}
.main.footer{
    padding: 0px 20px 0px 20px;
     width: 100%;
     height: fit-content;
 
    --bs-modal-footer-border-color:none;
}
 
   
.delete-btn{
   
    background-color: #041E3A !important;
    padding:16px 12px 15px 12px !important;
    font-family: foundersGroteskMonoMedium !important;
    font-size: 12px !important;
    line-height: 20px !important;
    letter-spacing: 1px !important;
    text-align: center !important;
    width: 43.3%;
    color: #FFFFFF !important;
    border: none;
    &:hover, &:active{
        background-color: #041E3A !important;
        color: #FFFFFF !important;
    }
  }
  .cancel-btn{
    padding: 15px 30px 15px 30px !important;
    font-family: foundersGroteskMonoRegular !important;
     background-color: #FFFFFF !important;
     color:#041E3A !important;
     font-size: 12px !important;
     line-height: 20px !important;
     letter-spacing: 1px;
     text-align: center !important;
     border: 1px solid #041e3a !important;
     width: 43.3%;
     &:hover, &:active{
        color:#041E3A !important;
        background-color: #FFFFFF !important;
    }
}
 
 
 
 
 
 
 