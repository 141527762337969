.userOnboardContainer {
  position: fixed;
  justify-self: center;
  top: 56%;
}

.carousel-container {
  position: relative;
  margin: 0 auto;

  .slick-slider .slick-track {
    display: flex;
  }
  .slick-slide {
    margin-right: 4px;
    margin-left: 4px;
  }

  .slick-list {
    overflow: hidden;
  }
  .slick-slide {
    height: 94px !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.01)
      );
    border-radius: 20px;
    padding: 12px 25px;
    backdrop-filter: blur(10px);
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    &_title {
      font-family: foundersGroteskTextRegular;
      color: #ffffff;
      font-size: 8px;
      line-height: 24px;
      letter-spacing: 2px;
      text-align: left;
      text-transform: uppercase;
      text-wrap: nowrap;
    }

    &_tryBtn {
      width: 45px;
      height: 20px;
      border-radius: 20px;
      border: 0.7px solid white;
      display: flex;
      align-items: center;
      font-size: 9px;
      line-height: 11.43px !important;
      letter-spacing: 1px;
      text-transform: capitalize;
      justify-content: center;
      background-color: transparent;
      color: white;
      text-wrap: nowrap;
      font-family: foundersGroteskTextRegular;
    }
  }

  .cardContent {
    display: block;
  }

  .cardContent-item {
    font-family: foundersGroteskTextRegular;
    color: #ffffff;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
  .next-arrow,
  .prev-arrow {
    position: absolute;
    height: 32px !important;
    width: 32px !important;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.01)
      );
  }
  .prev-arrow {
    left: -2.2rem;
    top: 32%;
  }
  .next-arrow {
    right: -2.5rem;
    bottom: 34%;
  }
  .next-icon {
    padding-left: 6px;
  }
  .prev-icon {
    padding-left: 10px;
  }
}

/****************   Media Queries  ******************/

//desktop
@media screen and (min-width: 960px) {
  .carousel-container {
    .slick-slide .next-arrow,
    .prev-arrow {
      &:hover {
        border: 1px solid #ffffff;
      }
    }
  }
}

.userOnboardContainer {
  @media screen and (max-width: 959px) {
    padding-left: 0.5px;
    padding-right: 0px;
    margin-top: 10px;
  }

  // responsiveness
  @media screen and (min-width: 960px) and (max-height: 800px) {
    top: 28rem;
  }
  @media screen and (max-width: 959px) and (max-height: 785px) {
    top: 27.5rem;
  }
  @media screen and (max-width: 576px) and (min-height: 786px) {
    top: 27.5rem;
  }

  //iphone responsiveness
  @media screen and (max-width: 430px) and (max-height: 550px) {
    top: 22rem;
  }
}

// Carousel Animation
.animateSearchwithCarousel {
  animation: movefromBottomSearch 1.5s forwards ease-in;
}

@keyframes movefromBottomSearch {
  0% {
    opacity: 0;
    transform: translateY(5rem);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
