.singleproduct {
  .productdetails-div {
    margin-bottom: 40px;
  }
  .divider {
    margin: 4px 0px 11px 0px;
  }

  .divider_margin {
    margin-bottom: 2px;
  }
  .product-heading {
    font-family: leJeuneDeck;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
    font-size: 28px;
    font-style: normal;
    line-height: 34px;
    text-transform: capitalize;
  }
  .badge-text {
    font-family: leJeuneDeck;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .sub-text {
    font-size: 16px;
    line-height: 30px;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
  }
  .intro-text {
    font-size: 16px;
    line-height: 30px;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
  }
  .sub-img {
    display: none;
  }
  .display-img {
    display: none;
  }
  .pic-div {
    margin-bottom: 24px;
    scroll-snap-type: x mandatory;
  }
  .sp-divider {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .sp-img {
    padding-left: 16px !important;
  }
  .show_truncate {
    overflow: visible !important;
    display: block !important;
    white-space: normal;
    height: auto;
  }
  .branding-conclusion {
    margin-left: 0px;
    margin-top: 40px;
  }
  .margin-right-20 {
    margin-right: 20px;
  }
  .mt {
    margin-top: 40px;
  }

  /***************   Media Queries   ********************/

  //dekstop

  // lg breakpoint
  @media screen and (min-width: 992px) {
    .pic-div {
      margin-bottom: 35px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .pdp_video_container {
      height: 486px;
    }
  }
  @media screen and (min-width: 1024px) {
    .sub-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    .pdp_lg_cursor {
      cursor: pointer;
    }
  }
  // xl breakpoint
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .pdp_xl_cursor {
      cursor: pointer;
    }
    .pdp_video_container {
      height: 580px;
    }
  }
  // xxl breakpoint
  @media screen and (min-width: 1400px) {
    .pdp_xxl_cursor {
      cursor: pointer;
    }
    .pdp_video_container {
      height: 675px;
    }
  }
  //mobile
  @media screen and (max-width: 959px) {
    .product-heading {
      font-size: 20px;
      line-height: 30px;
    }
    .sub-text {
      font-size: 13px;
      line-height: 22px;
    }
    .intro-text {
      font-size: 13px;
      line-height: 22px;
    }
    .main-section .para-main {
      font-size: 13px;
      line-height: 22px;
      padding-bottom: 16px;
    }
    .sp-divider {
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .branding-conclusion {
      margin-top: 30px;
      margin-bottom: 40px;
    }
    .mb-30 {
      margin-bottom: 30px;
    }
    .mt {
      margin-top: 30px;
    }
  }

  //  xs breakpoint
  @media screen and (max-width: 576px) {
    .sp-img {
      width: 300px;
      padding-left: 16px;
    }
    .sub-img {
      width: 300px;
      margin-left: 4px;
    }
    .image-size {
      width: 300px;
    }
    .display-img {
      width: 300px;
      margin-left: 3px;
    }
    .main-img {
      padding: 0;
      display: none;
    }
    .pic-div {
      flex-wrap: nowrap;
      height: auto;
      overflow-x: auto;
      width: 112.5%;
      margin-left: -32px;
    }
    .spd-img {
      height: 235px !important;
      width: 235px !important;
    }
    .pic-div::-webkit-scrollbar {
      display: none;
    }
    .productdetails-div {
      margin: auto;
    }
    .sub-img {
      display: block;
    }
    .display-img {
      display: block;
    }
    .intro-text {
      margin-left: -8px;
    }
    .left-margin {
      margin-left: -8px;
    }
    .branding-conclusion {
      margin-left: -10px;
    }
    .grid-me-20 {
      margin-right: 20px !important;
    }
  }
  .pic-div {
    @media screen and (min-width: 351px) and (max-width: 392px) {
      width: 119.5% !important;
    }
    @media screen and (min-width: 393px) and (max-width: 413px) {
      width: 118% !important;
    }
    @media screen and (min-width: 414px) and (max-width: 444px) {
      width: 116.5% !important;
    }
    @media screen and (min-width: 445px) and (max-width: 489px) {
      width: 115% !important;
    }
    @media screen and (min-width: 490px) and (max-width: 527px) {
      width: 113.5% !important;
    }
    @media screen and (max-width: 350px) {
      width: 113% !important;
    }
  }

  //  sm breakpoint
  @media screen and (min-width: 577px) and (max-width: 767px) {
    .main-img {
      padding: 0;
      display: none;
    }
    .sub-img {
      display: block;
      width: 320px;
      margin-left: 2px;
    }
    .sp-img {
      width: 320px;
    }
    .display-img {
      display: block;
      width: 320px;
      margin-left: -5px;
    }
    .pic-div {
      flex-wrap: nowrap;
      height: auto;
      overflow-x: auto;
      width: 105%;
      margin-left: -20px;
    }
    .pic-div::-webkit-scrollbar {
      display: none;
    }
    .spd-img {
      height: 235px !important;
      width: 335px !important;
    }
    .productdetails-div {
      margin: auto;
    }
    .grid-me-20 {
      margin-right: 20px !important;
    }
  }

  //  md breakpoint
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .main-img {
      padding: 0;
      display: none;
    }
    .video-sm {
      margin-top: 6px;
      margin-left: -16px;
    }
    .pic-div {
      flex-wrap: nowrap;
      height: auto;
      overflow-x: auto;
      width: auto;
      margin-left: 2px;
    }
    .pic-div::-webkit-scrollbar {
      display: none;
    }
    .sub-img {
      display: block;
      width: 320px;
    }
    .sp-img {
      width: 320px;
      padding-left: 16px;
    }
    .image-size {
      width: 300px;
    }
    .display-img {
      display: block;
      width: 320px;
    }
    .main-section .para-main {
      padding-bottom: 0px;
    }
    .productdetails-div {
      margin-bottom: 0px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 959px) {
    .sp-img {
      padding-left: 16px !important;
    }
    .grid-me-20 {
      margin-right: 20px !important;
    }
  }
  @media screen and (max-width: 992px) {
    .scroll-snap {
      scroll-snap-align: start;
    }
    .pic-div {
      scroll-padding-left: 20px;
      scroll-padding-right: 20px;
    }
  }
}
