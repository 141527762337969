@keyframes userOnboardBackground{
    from {
      transform:scale(1);
      opacity:0.5;
    }
    to {
      transform: scale(1.02);
      opacity: 1;
    }
  }
  
  .animateSearch{
    animation: movefromBottomSearch 1.5s forwards ease-in;
   }
  
    @keyframes movefromBottomSearch {
    0% {
      opacity: 0;
      transform: translateY(5rem);
    }
    80% {
      opacity: 0.5;    
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }  
  }
  
  .animateSearchOut{
    animation: moveToBottomSearch 1s forwards ease-in;
   }
  
  @keyframes moveToBottomSearch {
    0% {
      opacity: 1;
      transform: translate(0);
    }
    80% {
      opacity: 0.5;    
    }
    100% {
      opacity: 0;
      transform: translateY(5rem);
    }  
  }
  .carousel-out{
    animation: carouselOut 1s forwards ease-in;
  }
  @keyframes carouselOut {
    0% {
      opacity: 1;
      transform: translate(0);
    }
    80% {
      opacity: 0.5;    
    }
    100% {
      opacity: 0;
      transform: translateY(5rem);
    }  
  }

  .backgroundOut {
    animation: backOutImg 1s ease-out forwards !important;
  }
  
  @keyframes backOutImg {
    from {
      transform: translate(0px, -5px) scale(1.02);
      opacity: 1;
    }
    to {
      transform: translate(-15, -5px) scale(1);
      opacity: 0;
    }
  }
  .onboard-loader{
    width: 8px;
    height: 8px;
    position:absolute;
    top: 25px;
    right: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: loader 1s infinite linear alternate;
    @media screen and (max-width: 959px) {
      top: 48px;
    }
  }
  
  @keyframes loader {
    0% {
      box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
      background:#6D6F73;
    }
    33% {
      box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
      background: #0002;
    }
    66% {
      box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
      background: #0002;
    }
    100% {
      box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
      background: #6D6F73;
    }
  } 