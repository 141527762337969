body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background-image: url(../../public/static/assets/background.jpg);
    // background-size: cover;
    // overflow:hidden;
    // background: #f8e6d8 !important;
    // background: #dfdfdf !important;
      // background-image: url(../../../public/static/assets/Landing_Desktop.jpg);
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-attachment: fixed;
      // min-height: 100%;
      // min-width: 100%;
      // animation: userOnboardBackground 3s ease-in forwards;
      // width: 100%;
      // height: 100%;
      // position: fixed;
      // top: 0;
      // left: 0;
      // @media screen and (max-width: 767px){
      //   background-image: url(../../../public/static/assets/Landing-mobile-bg.jpg);
      //   -webkit-background-size: cover;
      //   background-attachment: inherit;
      //   position: fixed;
      //   margin: auto 0;
      // }
    // @media screen and (max-width:430px){
    //   min-height: 100vh;
    //   min-height: -webkit-fill-available;
    // }
  }
  body::-webkit-scrollbar {
    display: none;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}