.archive-container {
  margin: 0 auto;
  .slick-slider .slick-track {
    display: flex;
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }

  .content-container {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.01)
      );
    backdrop-filter: blur(10px);
    padding: 12px 24px;
    border-radius: 20px;
    height: 94px;
    margin: 0px 4px;
  }

  .textTruncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .iconStyle {
    font-size: 13px;
    color: white;
    height: 14px;
    width: 14px;
  }
  .fontStyle {
    font-family: foundersGroteskTextRegular;
    font-size: 13px;
    color: white;
    line-height: 20px;
  }
  .day-style-div {
    position: fixed;
    top: 3.8rem;
  }
  .three-dots {
    position: fixed !important;
    right: 7% !important;
  }

  .dayStyle {
    font-family: foundersGroteskTextRegular;
    font-size: 8px;
    line-height: 24px;
    letter-spacing: 2px;
    color: white;
    text-transform: uppercase;
  }

  .tooltipBtn {
    height: 4px;
    border: 1px solid black;
    background-color: transparent;
    color: white;
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: 700;
  }

  .threeDotStyle {
    word-spacing: 5px;
  }
  .customTooltip {
    border: 1px solid white;
    border-radius: 10px;
    position: static;
    margin-right: -1.9rem;
    padding: 16px 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.01)
      );
    backdrop-filter: blur(10px);
    display: block;
    z-index: 1000;
    width: fit-content;
    justify-self: end;
  }
  .customTooltip ::before {
    content: "";
    position: absolute;
    top: -11px;
    left: 52%;
    transform: translateX(-50%);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 10px solid white;
  }
  .customTooltip ::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 52%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #514f40;
  }
  .custom-xs-tooltip {
    border: 1px solid white;
    border-radius: 10px;
    padding: 5px 10px;
    background: white;
    width: 100%;
  }

  .textTooltip {
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &_font {
      font-family: foundersGroteskMonoRegular;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}
.newchat_txt {
  margin-bottom: 30px;
}
.modal-content_inner {
  .modal-header .cross {
    padding: 0 !important;
  }

  .btnChat {
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    font-family: foundersGroteskMonoMedium;
    border: 1px solid #041e3a !important;
  }
}
.modal_body_title2 {
  font-family: foundersGroteskTextRegular;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--polo-primary, #041e3a);
}
.slick-list {
  overflow: hidden;
}

.nextAr-arrow,
.prevAr-arrow {
  position: absolute;
  height: 32px !important;
  width: 32px !important;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01));
  top: 30%;
  text-align: center;
}

.prevAr-arrow {
  left: -2.2rem;
}

.nextAr-arrow {
  right: -2.4rem;
}
.tooltip_close {
  margin-top: 23px;
  margin-right: 5px;
}
.smlPad {
  padding-top: 0.5px;
}

/***************** Media Queries *********************/

// desktop
@media screen and (min-width: 960px) {
  .content-container {
    &:hover {
      border: 1px solid #ffffff;
    }
  }
  .three-dots {
    cursor: pointer;
  }
}

// mobile
@media screen and (max-width: 767px) {
  .archive-container {
    .slick-slider .slick-track {
      min-width: 275px !important;
    }
  }
}

@media screen and (min-width: 577px) {
  .archive-container .slick-slide {
    width: 262px !important;
  }
}

.customTooltip {
  @media screen and (max-width: 576px) {
    margin-left: 0;
  }
  @media screen and (min-width: 577px) and (max-width: 620px) {
    margin-right: -2rem;
  }
  @media screen and (min-width: 700px) and (max-width: 767px) {
    margin-right: -1.7rem;
  }
  @media screen and (min-width: 900px) and (max-width: 991px) {
    margin-right: -1.7rem;
  }
  @media screen and (min-width: 992px) and (max-width: 1059px) {
    margin-right: -2.1rem;
  }
  @media screen and (min-width: 1100px) and (max-width: 1159px) {
    margin-right: -1.85rem;
  }
  @media screen and (min-width: 1160px) and (max-width: 1399px) {
    margin-right: -1.8rem;
  }
}

//Tooltip modal animation
.tooltip-fadein {
  animation: tooltipFadein 0.5s forwards ease-in;
}
@keyframes tooltipFadein {
  0% {
    transform: translateY(10rem);
  }
  100% {
    transform: translate(0);
  }
}
.tooltip-fadeout {
  animation: tooltipFadeout 0.5s forwards ease-in;
}
@keyframes tooltipFadeout {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translateY(10rem);
  }
}
