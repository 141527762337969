.chat_msg1 {
  font-size: 16px;
  line-height: 30px;
  font-family: foundersGroteskTextRegular;
  font-style: normal;
  font-weight: 400;
  color: var(--polo-primary, #041e3a);  
  border-radius: 10px;
  .chat2 {
    font-family: foundersGroteskTextRegular;
    font-size: 16px;
    line-height: 30px;
    color: var(--polo-primary, #041e3a);
  }
}
 
.branding-conclusion {  
  font-size: 16px;
  line-height: 30px;
  font-family: foundersGroteskTextRegular;
  font-style: normal;
  font-weight: 400;
  color: var(--polo-primary, #041e3a);
}
 
  .branding-divider{
    margin-top: 30px;
    margin-bottom: 40px;
  }
 
  .branding-video-css{
    height: 100% !important;
  }
 
  .branding-video-div{
    margin-top: 30px;
    margin-bottom: 24px;
  }
 
  .branding-mb{
    margin-bottom: 24px !important;
  }
  .branding-card{
    margin-bottom: 40px !important;
  }
 
 
//********************************//********************************
      //************   Media Query     *************//
//********************************//********************************
 
@media screen and (max-width: 959px) {
  .chat_msg1 {
    font-size: 13px;
    line-height: 22px;
 
    .chat2 {
      font-size: 13px;
      line-height: 22px;
    }
  }  
 
  .branding-conclusion {
    font-size: 13px;
    line-height: 22px;
  }
  .branding-divider{
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .branding-video-div{
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .branding-mb{
    margin-bottom: 16px !important;
  }
}
 
 
@media screen and (min-width: 1024px) {
  .branding-video-css{
    height: 430px !important;
  }
}