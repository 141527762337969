.brandingcss{
    img {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 960px){
      max-width: 300px;
    }
  }
  .product-detail.truncate {
    color: var(--polo-primary, #041e3a);
    font-size: 16px !important;
    line-height: 30px !important;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    max-width: 600px !important;
    @media screen and (max-width: 959px) {
      font-size: 13px !important;
      line-height: 22px !important;
    }
  }
  
  @media (max-width: 576px){
    .sc-div{
      margin-left: -18px !important
    }
  }
  .product-header-title {
    font-size: 28px;
    line-height: 34px;
    font-family: leJeuneDeck; 
    color: #041E3A;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize; 
      @media screen and (max-width: 959px){
        font-size: 20px;
        line-height: 30px;
        margin-top: 2px;
      }
    @media screen and (min-width: 960px){
      max-width: 600px;
    }
  }
  .badge{
    margin-top: 24px;
    @media screen and (max-width: 959px){
      margin-top: 16px;
    }
  }
  @media screen and (min-width: 960px) {
    .truncate {
      margin-top: 10px !important;
      margin-bottom: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .show-truncate-text {
      overflow: visible; 
      display: block;
      white-space: normal;
      height:auto;
    }
  
  }
  @media screen and (max-width: 959px) {
    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      line-clamp: 5;
      -webkit-box-orient: vertical;
      font-size: 13px !important;
      line-height: 22px !important;      
      margin-top: 4px !important;
      margin-bottom: 18px !important;
      color: var(--polo-primary, #041e3a);
    }
    .show-truncate-text {
      overflow: visible; 
      display: block;
      white-space: normal;
      height:auto;
    }
  
    .product-cat-Text {
      color: var(--polo-primary, #041e3a);
      font-family: foundersGroteskTextMedium;
      font-size: 9px !important;
      margin-top: 10px !important;
      line-height: 16px !important;
      letter-spacing: 2px !important;
    }
  }
  
  .product-cat-Text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;
    color: var(--polo-primary, #041e3a);
    font-family: foundersGroteskTextMedium;
    margin-bottom: 10px;
  }
  
  .my-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sc-subdiv{
    padding-bottom:20px;
    @media screen and (max-width:959px){
      padding-bottom:30px;
      margin-top: 0px;
    }
  }
    .product-list-btm-divider{
      margin-top: -36px;
      @media screen and (max-width: 959px){
        margin-top: 0px;
      }
    }
    .product-list-top-divider{
      margin-bottom: -16px;
      @media screen and (max-width: 959px){
        margin-bottom: 0px;
      }
    }   
  @media screen and (max-width: 576px){
    .xs_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 767px){
    .sm_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 959px){
    .md_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 960px) and (max-width: 991px) {
    .mdcursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .xl_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 1400px) {
    .xxl_cursor{
      cursor: pointer;
    }
    .intro_text{
      margin-left: -4% !important;
    }
  }
  .editorial-img{
    min-width: 200px;
  }