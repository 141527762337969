.feedback-btn {
  border-radius: 5px;
  border: 1px solid rgba(4, 30, 58, 0.5);
  background: rgba(255, 255, 255, 0);
  padding: 3px 10px;
  color: var(--polo-primary, #041e3a);
  font-family: foundersGroteskTextMedium;
  font-size: 14px;
}

.feedback-content {
  width: 500px !important;
  border: none;
}

.close_btn {
  padding: 7px 15px 0px 0px;
  border: none;
  background: none;
  cursor: pointer;
}

.headline {
  font-size: 18px;
  line-height: 21.6px;
  color: #041e3a;
  font-family: leJeuneDeck;
  text-align: center;
}

.feedback-subtext {
  font-size: 12px;
  color: #041e3a;
  line-height: 19px;
  letter-spacing: 0.25px;
  font-family: foundersGroteskTextRegular;
  text-align: center;
}

.feedback-icon {
  background-color: transparent;
  border: 1.09px solid #041e3a26;
  display: flex;
  align-items: center;
  border-radius: 6.39px;
  padding: 10.22px 20.44px 10.22px 20.44px;
}

.char-count {
  font-size: 10px;
  line-height: 12px;
  color: #6d6f73;
  font-family: foundersGroteskTextRegular;
  text-align: end;
  margin-bottom: -10px !important;
}

.submit_btn {
  letter-spacing: 1px;
  font-size: 11px !important;
  font-family: foundersGroteskMonoRegular !important;
  font-weight: 400;
  line-height: 14px !important;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff !important;
}

/***************   Media Queries   ********************/

// xs breakpoint
@media screen and (max-width: 576px) {
  .feedback-content,
  .feedback-textarea {
    width: 100% !important;
  }
  .submit_btn {
    font-family: foundersGroteskTextMedium;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
  }
}
