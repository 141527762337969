.TextResponse {
  .chat_msg1 {
    font-size: 16px;
    line-height: 30px;
    color: #041E3A;
    border-radius: 10px;
    padding: 12px;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;

    .chat2 {
      font-size: 16px;
      line-height: 30px;
      font-family: foundersGroteskTextRegular;
      font-style: normal;
      font-weight: 400;
      color: var(--polo-primary, #041e3a);
    }
  }

  .text-res-divider{
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .text-res-conclusion{
    font-size: 18px;
    line-height: 32px;
    color: #041E3A;
    font-style: normal;
    font-family: leJeuneDeck;
  }
 }

//********************************
   //*****Media Query*****//
//********************************

@media screen and (max-width: 959px) {

.TextResponse {
    .chat_msg1 {
      font-size: 13px !important;
      line-height: 22px !important;
    .chat2 {
      font-size: 13px;
      line-height: 22px;
    }
  }
    .text-res-divider{
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .text-res-conclusion{
      font-size: 14px;
      line-height: 24px;
    }
  }
}
