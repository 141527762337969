.useronboard {
  background-image: url(../../../public/static/assets/UpdatedLandingDesktop.jpg);
  animation-duration: 4s;
  background-repeat: no-repeat;
  background-position: top center !important;
  background-size: cover;
  min-height: 100%;
  background-attachment: fixed;
  min-width: 100%;
  animation: userOnboardBackground 3s ease-in forwards;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
 
  .closebtn {
    margin-left: 90%;
    margin-top: 4px;
    margin-bottom: 6px;
    border: none;
    background: none;
  }
  // .pb_4 {
  //   padding-bottom: 4px;
  // }
 
  .msg::placeholder {
    color: #9d9fa3;
  }
  .logo {
    text-align: center;
    color: white;
    font-size: 80px;
    font-family: leJeuneDeck;
    opacity: 100%;
  }
  .send-arrow-position {
    right: 15px !important;
  }
 
 
  .msg {
    font-family: leJeuneDeck;
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 12px 60px 12px 24px;
  }
 
  .send-arrow-position {
    right: 25px;
  }
 
  .animation-msg {
    animation: blink 6s linear infinite;
  }
 
  .message-fade {
    text-align: start;
    top: 0.5px;
    padding-left: 2rem;
    font-size: 18px;
    line-height: 24px;
    color: #6d6f73;
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
 
 
 
 
.logo.focused,
.chatbox.focused {
  transform: translateY(-2vh);
}
 
.chatbox {
  text-align: center;
  width: 100%;
  border-radius: 13px;
  input {
    width: 100%;
  }
  .form-control {
    padding: 12px 70px 12px 24px;
    font-family: leJeuneDeck;
    font-size: 18px;
    line-height: 24px;
    border-radius: 13px;
    border: none;
    max-height: 170px;
    min-height: 60px;
    resize: none;
    position: absolute;
    bottom: -55px;
    top: auto;
    overflow: auto;
  }
  .chat {
    padding-top: 17px;
  }
  .form-control:focus {
    box-shadow: none;
  }
  span {
    position: absolute;
    padding: 12px;
    right: 15px;
  }
 
  .send-arrow-position {
    position: absolute;
    right: 15px;
    top: 1px;
  }
 
  .send-arrow-position.enabled {
    cursor: pointer;
    border: none;
  }
  .send-arrow-position.disabled {
    border: none;
    background: none !important;
    cursor: default;
  }
  .textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
  }
}
 
 
//********************************//********************************
      //************   Media Query     *************//
//********************************//********************************
 
@media screen and (max-width: 959px) {
  .chatbox {
    .form-control {
      padding: 15px 60px 15px 18px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 18px;
      min-height: 50px !important;
      bottom: -48px;
    }
  }
    .useronboard {
      .closebtn {
        margin-top: 4px;
        margin-bottom: 0px;
        padding: 5px 6px 3px 6px !important;
      }
       // mobile
      .logo {
        position: fixed !important;
        top: 26%;
        transition: transform 0.35s ease-in-out;
      }
      .chatbox {
        position: fixed;
        transition: transform 0.35s ease-in-out;
      }
      .chatbox.box-msg {
        height: 50px;
        margin: 20px;
        width: 90% !important;
        margin-left: 20px;
        margin-right: 20px;
        position: fixed !important;
        border-radius: 15px;
        gap: 10px;
        top: 42%;
      }
      .arrow-dist {
        position: absolute !important;
        top: 75% !important;
        padding: 1px 2px !important;
      }
      .msg {
        padding: 0px, 18px, 0px, 18px !important;
        height: 50px;
        margin: 20px !important ;
        font-size: 14px;
        line-height: 18px;
        padding-top: 176px;
        min-height: 50px !important;
        @media screen and (min-height: 914px) {
          height: 50px !important;
        }
      }
      .arrow_long {
        height: 22px !important;
        width: 22px !important;
      }
      .send-arrow-position {
        right: 25px;
      }
 
      .msg{
        padding: 15px 45px 15px 18px !important;
      }
      .message-fade {
        font-size: 14px;
        line-height: 18px;
        top: 32px;
        }
    }
 
   
}
 
 
@media screen and (max-width: 767px) {
  .useronboard {
  background-image: url(../../../public/static/assets/Landing-mobile-bg.jpg);
  background-size: cover !important;
  background-position: center top;
  -webkit-background-size: cover;
  }
}
@media screen and (max-width: 400px) {
  .useronboard {
  overflow-x: hidden !important;
  }
}
 
@media screen and (max-width: 359px) {
  .useronboard{
    .message-fade{
  padding-right: 25px !important;
  top: 23px !important;
    }
  }
}
 
//common breakpoint mobile//
@media screen and (max-width: 576px) {
  .useronboard{
    .logo {
      height: 105px !important;
      width: 269px !important;
    }
    .logo-img {
      height: 105px !important;
      width: 269px !important;
      max-width: none;
    }
    .chatbox.box-msg {
      top: 48.88%;
      position: fixed !important;
    }
  }
}
 
 
@media screen and (min-width: 577px) and (max-width: 959px) {
  .useronboard{
  .logo {
    // logo scaling
    height: auto !important;
    width: 50% !important;
    left: 27% !important;
  }
}
}
 
@media screen and (min-width: 960px) and (max-height: 780px) {
  .useronboard{
    overflow: auto;
    .logo {
      top: 12rem !important;
      position: fixed;
    }
    .chatbox.box-msg {
      margin: 0 auto !important;
      top: 23rem !important;
    }
 }
}
@media screen and (max-width: 959px) and (max-height: 780px) {
  .useronboard{
    overflow: auto;
    .logo {
      top: 12.7rem !important;
      position: fixed;
    }
    .chatbox.box-msg {
      margin: 0 auto !important;
      top: 21.8rem !important;
    }
}
}
@media screen and (max-width: 576px) and (min-height: 781px) {
.useronboard{
  overflow: auto;
  .logo {
    top: 12.7rem !important;
    position: fixed;
  }
  .chatbox.box-msg {
    margin: 0 auto !important;
    top: 21.8rem !important;
  }
}
}
@media screen and (max-width: 576px) and (min-height: 915px) {
    .useronboard{
    overflow: auto;
    .logo {
      top: 12.7rem !important;
      position: fixed;
    }
    .chatbox.box-msg {
      margin: 0 auto !important;
      top: 21.8rem !important;
    }
  }
}
//iphone responsiveness
@media screen and (max-width: 430px) and (max-height: 550px) {
  .useronboard{
    .logo {
      top: 8rem !important;
      position: fixed;
    }
    .chatbox.box-msg {
      margin: 0 auto !important;
      top: 16rem !important;
    }
  }
}
 
@media screen and (min-width: 577px) and (max-width: 959px) and (min-height: 914px) {
  .useronboard{
    .logo {
      top: 26% !important;
    }
    .chatbox.box-msg {
      top: 42% !important;
    }
 }
}
 
   //desktop//
   @media screen and (min-width: 960px), screen and (min-height: 914px) {
    .useronboard{
      .logo {
        width: 450px;
        position: absolute;
        top: calc(18.5% + 50px);
      }
 
      .chatbox.box-msg {
        position: absolute;
        width: 911px;
        top: 47%;
      }
      .arrow-dist {
        position: absolute !important;
        padding-top: 0.7%;
      }
      .arrow-dist:hover {
        padding: 1px 14px 0.5px 14px !important;
        right: 7px !important;
        top: 6.5px !important;
        background: #f2f3f5 !important;
        border-radius: 10px;
        cursor: pointer;
      }
      .msg {
        height: 60px;
        font-size: 18px !important;
        min-height: 50px !important;
        max-height: 170px !important;
        padding-top: 0.9% !important;
      }
      .arrow_long {
        height: 36px;
        width: 36px;
      }
      .chatbox span {
        right: 12px;
      }
      .send-arrow-position {
        right: 25px;
      }
    }
  }
 
@media screen and (max-height: 720px) {
  .useronboard{
    overflow: auto;
  }
}